import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React from "react"
import styles from "./index.module.scss"

export const SquareBoxContainer = ({ children, className, style }) => {
  return (
    <div
      className={`mt-5 d-flex  flex-wrap justify-content-center ${className}`}
      style={style}
    >
      {children}
    </div>
  )
}
SquareBoxContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const SquareBox = ({ href, className, style, icon, header, text }) => {
  let element = (
    <div
      className={`${className} ${styles.squareBox} m-3 text-light rounded d-flex justify-content-center align-items-center flex-column `}
      style={{ width: 330, height: 110, ...style }}
    >
      <span className="font-weight-bold" style={{ fontSize: 18 }}>
        <FontAwesomeIcon size="lg" icon={icon} /> {header}
      </span>
      <span>{text}</span>
    </div>
  )

  return href ? (
    <a
      className="text-decoration-none"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {element}
    </a>
  ) : (
    element
  )
}
SquareBox.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.object,
  header: PropTypes.string,
  text: PropTypes.string,
}
