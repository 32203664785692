import { SquareBox, SquareBoxContainer } from "components/square-box"
import {
  faEnvelope,
  faLocationArrow,
  faMobile,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import { faTelegram, faWhatsapp } from "@fortawesome/free-brands-svg-icons"

import Container from "react-bootstrap/Container"
import Layout from "components/layout"
import LocalizedLink from "components/localized-link"
import React from "react"
import SEO from "components/seo"
import styles from "./index.module.scss"
import useTranslations from "components/use-translations"

export default function Iletisim() {
  const {
    genel: {
      iletisim,
      iletisimeGec,
      harita,
      telefon,
      ePosta,
      kullanimKilavuzu,
      destek,
      mobil,
      adres,
      konum,
    },
    iletisim: {
      seoTitle,
      seoDescription,
      baslikSatir1,
      baslikSatir2,
      baslikSatir3,
      destekAciklama,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/iletisim"
        datePublished="2016-08-25T15:22:20+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
      />
      <Container
        className="text-center"
        style={{ paddingTop: 90, paddingBottom: 80, minHeight: 500 }}
      >
        <h1
          className={`font-weight-bold text-center mb-4 ${styles.headertext}`}
          style={{ letterSpacing: 2 }}
        >
          {iletisim}
        </h1>

        <h5
          className="mt-5 text-dark"
          style={{ fontSize: 18, lineHeight: 1.5 }}
        >
          {baslikSatir1}
          <br /> {baslikSatir2}
          <br />
          <span className="font-weight-bold">{baslikSatir3}</span>
        </h5>
        <div className="mt-5 d-flex justify-content-center align-items-center">
          <a
            className="btn btn-primary shadow mx-2 d-flex justify-content-center align-items-center"
            href="#iletisime-gec"
            role="button"
            style={{ width: 200, height: 50 }}
          >
            {iletisimeGec}
          </a>

          <LocalizedLink
            to="/kutuphane-programi/kullanim-kilavuzu"
            className="btn btn-light shadow mx-2 d-flex justify-content-center align-items-center"
            role="button"
            style={{ width: 200, height: 50 }}
          >
            {kullanimKilavuzu}
          </LocalizedLink>
        </div>
      </Container>
      <div className="border-top"></div>
      <Container
        id="iletisime-gec"
        className="text-center"
        style={{ paddingTop: 60, paddingBottom: 80, minHeight: 500 }}
      >
        <h1
          className="font-weight-bold"
          style={{ fontSize: 42, letterSpacing: -1 }}
        >
          {destek}
        </h1>
        <p className="text-center text-md-left mt-5" style={{ fontSize: 18 }}>
          {destekAciklama}
        </p>

        <SquareBoxContainer>
          <SquareBox
            className="bg-primary"
            href="mailto:bilgi@kodvizit.com"
            icon={faEnvelope}
            header={ePosta}
            text="bilgi@kodvizit.com"
          ></SquareBox>
          <SquareBox
            className="bg-success"
            href="https://api.whatsapp.com/send?phone=908504414134&text=Merhaba, destek istiyorum."
            icon={faWhatsapp}
            header="Sadece WhatsApp"
            text="0850 441 41 34"
          ></SquareBox>
          <SquareBox
            className="bg-info"
            href="https://t.me/kodvizit"
            icon={faTelegram}
            header="Telegram"
            text="@kodvizit"
          ></SquareBox>
          <SquareBox
            className="bg-danger"
            href="https://goo.gl/maps/LjM7YWmn4bNGC2WeA"
            icon={faLocationArrow}
            header={adres}
            text="Gaziler Mah. Şht Erdem Demir Cad. No:30 Gebze/Kocaeli"
          ></SquareBox>
        </SquareBoxContainer>
      </Container>
      <div className="border-top"></div>
      <Container
        id="iletisime-gec"
        className="text-center"
        style={{ paddingTop: 60, paddingBottom: 80 }}
      >
        <h1
          className="font-weight-bold mb-5"
          style={{ fontSize: 42, letterSpacing: -1 }}
        >
          {konum}
        </h1>
        <div
          style={{
            overflow: "hidden",
            paddingBottom: "30.25%",
            position: "relative",
            height: 0,
          }}
        >
          <iframe
            title={harita}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.772855668941!2d29.427176315407916!3d40.810984979321226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb2099d84b160d%3A0xf951a9fcb8423044!2sGaziler%2C%20%C5%9Eht.%20Erdem%20Demir%20Cd.%20No%3A30%2C%2041400%20Gebze%2FKocaeli!5e0!3m2!1str!2str!4v1592502876355!5m2!1str!2str"
            width="1000"
            height="400"
            style={{
              border: 0,
              left: 0,
              top: 0,
              height: "100%",
              width: "100%",
              position: "absolute",
            }}
          ></iframe>
        </div>
      </Container>
    </Layout>
  )
}
